import React from "react"
import mixpanel from "mixpanel-browser"

import { MixpanelContext } from "./src/analytics/tracking"

export const wrapRootElement = ({ element }) => {
  mixpanel.init("98f1ae557a93c28aeab0a45584cce63d")
  if (process.env.NODE_ENV !== "production") {
    mixpanel.disable()
  }

  return (
    <MixpanelContext.Provider value={mixpanel}>
      {element}
    </MixpanelContext.Provider>
  )
}
