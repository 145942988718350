// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-index-js": () => import("./../../../src/pages/aboutus/index.js" /* webpackChunkName: "component---src-pages-aboutus-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-dawn-project-index-js": () => import("./../../../src/pages/DAWN-Project/index.js" /* webpackChunkName: "component---src-pages-dawn-project-index-js" */),
  "component---src-pages-deeplink-private-index-js": () => import("./../../../src/pages/deeplink-private/index.js" /* webpackChunkName: "component---src-pages-deeplink-private-index-js" */),
  "component---src-pages-feedhindi-feed-landing-form-js": () => import("./../../../src/pages/feedhindi/feed-landing-form.js" /* webpackChunkName: "component---src-pages-feedhindi-feed-landing-form-js" */),
  "component---src-pages-feedhindi-index-js": () => import("./../../../src/pages/feedhindi/index.js" /* webpackChunkName: "component---src-pages-feedhindi-index-js" */),
  "component---src-pages-feedhindi-thankyou-js": () => import("./../../../src/pages/feedhindi/thankyou.js" /* webpackChunkName: "component---src-pages-feedhindi-thankyou-js" */),
  "component---src-pages-feedpun-feed-form-p-js": () => import("./../../../src/pages/feedpun/feed-form-p.js" /* webpackChunkName: "component---src-pages-feedpun-feed-form-p-js" */),
  "component---src-pages-feedpun-index-js": () => import("./../../../src/pages/feedpun/index.js" /* webpackChunkName: "component---src-pages-feedpun-index-js" */),
  "component---src-pages-feedpun-thankyou-js": () => import("./../../../src/pages/feedpun/thankyou.js" /* webpackChunkName: "component---src-pages-feedpun-thankyou-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moo-sathi-index-js": () => import("./../../../src/pages/moo-sathi/index.js" /* webpackChunkName: "component---src-pages-moo-sathi-index-js" */),
  "component---src-pages-moo-sathi-thanks-js": () => import("./../../../src/pages/moo-sathi/Thanks.js" /* webpackChunkName: "component---src-pages-moo-sathi-thanks-js" */),
  "component---src-pages-moo-sathi-welcome-js": () => import("./../../../src/pages/moo-sathi/Welcome.js" /* webpackChunkName: "component---src-pages-moo-sathi-welcome-js" */),
  "component---src-pages-mooofarmbuy-index-js": () => import("./../../../src/pages/mooofarmbuy/index.js" /* webpackChunkName: "component---src-pages-mooofarmbuy-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-sell-catalog-index-js": () => import("./../../../src/pages/sell-catalog/index.js" /* webpackChunkName: "component---src-pages-sell-catalog-index-js" */),
  "component---src-pages-sell-landing-index-js": () => import("./../../../src/pages/sell-landing/index.js" /* webpackChunkName: "component---src-pages-sell-landing-index-js" */),
  "component---src-pages-solution-index-js": () => import("./../../../src/pages/solution/index.js" /* webpackChunkName: "component---src-pages-solution-index-js" */),
  "component---src-pages-stories-awards-index-js": () => import("./../../../src/pages/stories-awards/index.js" /* webpackChunkName: "component---src-pages-stories-awards-index-js" */),
  "component---src-pages-super-sathi-h-index-js": () => import("./../../../src/pages/super-sathi-h/index.js" /* webpackChunkName: "component---src-pages-super-sathi-h-index-js" */),
  "component---src-pages-super-sathi-h-super-sathi-form-js": () => import("./../../../src/pages/super-sathi-h/super-sathi-form.js" /* webpackChunkName: "component---src-pages-super-sathi-h-super-sathi-form-js" */),
  "component---src-pages-super-sathi-h-thankyou-js": () => import("./../../../src/pages/super-sathi-h/thankyou.js" /* webpackChunkName: "component---src-pages-super-sathi-h-thankyou-js" */),
  "component---src-pages-super-sathi-p-index-js": () => import("./../../../src/pages/super-sathi-p/index.js" /* webpackChunkName: "component---src-pages-super-sathi-p-index-js" */),
  "component---src-pages-super-sathi-p-sathi-form-js": () => import("./../../../src/pages/super-sathi-p/sathi-form.js" /* webpackChunkName: "component---src-pages-super-sathi-p-sathi-form-js" */),
  "component---src-pages-super-sathi-p-thankyou-js": () => import("./../../../src/pages/super-sathi-p/thankyou.js" /* webpackChunkName: "component---src-pages-super-sathi-p-thankyou-js" */),
  "component---src-pages-terms-and-conditions-index-js": () => import("./../../../src/pages/terms-and-conditions/index.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-js" */),
  "component---src-pages-thanks-index-js": () => import("./../../../src/pages/Thanks/index.js" /* webpackChunkName: "component---src-pages-thanks-index-js" */),
  "component---src-pages-welcome-moofarm-index-js": () => import("./../../../src/pages/WelcomeMoofarm/index.js" /* webpackChunkName: "component---src-pages-welcome-moofarm-index-js" */)
}

